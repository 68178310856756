<template>
  <div class="m-4">
    <h3 class="m-1 mb-2">Resume Medis Rawat Inap</h3>

    <v-row dense>
      <v-col cols="12" lg="8" sm="8" md="8">
        <div>
          <v-card color="teal darken-1" dark>
            <v-card-text>
              <v-row class="mt-1 mb-0">
                <v-col
                  v-for="(v, i) in master.demografi"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-1 mb-1 pt-1 pb-0"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.demografi[i] = e)"
                    :value="data.demografi[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :disabled="v.disabled"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <v-divider class="mt-3 mb-3" />
        <v-card>
          <v-card-title>Form Resume Medis Rawat Inap</v-card-title>

          <v-card-text>
            <v-row class="mt-0 mb-0" style="font-size: 12px !important">
              <v-col
                v-for="(v, i) in master.form1"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form1[i]"
                  :disabled="v.disabled"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" sm="4" md="4">
        <div v-if="cekRole()">
          <v-autocomplete
            v-model="selectedItem"
            :items="itemsKamar"
            :loading="loading"
            item-text="text"
            item-id="id"
            outlined
            clearable
            label="Pergantian Kamar"
            :search-input.sync="search"
          ></v-autocomplete>
          <v-btn
            color="red darken-1"
            class="btn-block"
            @click="gantiruangan()"
            dark
            >Ganti Ruangan</v-btn
          >
        </div>
        <v-card class="mt-2">
          <v-card-title>History Resume Medis</v-card-title>
          <v-card-text>
            <div
              class="list-group mt-0"
              v-for="(itemresume, index) of listData"
              :key="index"
            >
              <div
                class="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-1">
                    <b color="primary-text">Dokter DPJP&nbsp;:&nbsp;</b
                    >{{ itemresume.dokterUtama }}
                  </p>
                  <small>{{ itemresume.created_at }}</small>
                </div>
                <p class="mb-1">
                  <v-btn
                    color="orange darken-2"
                    @click="cetakResumeMedis(itemresume)"
                    elevation-2
                    dark
                    medium
                    class="btn-block mt-1"
                    >Cetak Resume &nbsp;<v-icon small
                      >mdi-printer</v-icon
                    ></v-btn
                  >
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn
          @click="postSave"
          :hidden="showDialog"
          color="primary"
          medium
          class="btn-block"
        >
          Simpan Resume Medis
        </v-btn>
        <v-btn
          class="btn-block"
          @click="showDialog = true"
          dark
          medium
          :hidden="showDialog"
          color="purple"
          >Surat Kontrol</v-btn
        >
        &nbsp;
      </div>
    </div>
    <v-dialog :persistent="persistent" v-model="showDialog" :max-width="max">
      <v-card>
        <v-card-title>FORM KIRIM SURAT KONTROL</v-card-title>
        <v-card-subtitle
          ><p class="text-danger">
            UNTUK KONTROL SETELAH RAWAT INAP, PILIH SEP RAWAT INAP TIDAK
            BENERKAN MEMILIH YANG RAWAT JALAN!!!
          </p></v-card-subtitle
        >
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-2">
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-select
                  v-model="SEP"
                  :items="items"
                  return-object
                  outlined
                  item-id="id"
                  item-text="text"
                  :rules="rulesSep"
                  label="Pilih SEP"
                  dense
                >
                  <template v-slot:item="{ item }">
                    <b>Jenis Layanan:&nbsp;</b>
                    <span v-html="item.jenisPelayanan"></span>&nbsp;
                    <b>No.SEP:</b>&nbsp;{{ item.text }}&nbsp;<b>Tanggal SEP</b
                    >:&nbsp;<span class="red--text" v-html="item.tgl_sep"></span
                    >&nbsp;<b>Poli</b>:&nbsp;<span v-html="item.poli"></span>
                    &nbsp;<b>No.Rujukan</b>:&nbsp;<span
                      v-html="item.noRujukan"
                    ></span>
                  </template>
                </v-select>
                <v-text-field
                  v-model="noNik"
                  outlined
                  dense
                  disabled
                  label="No.NIK"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8" lg="8">
                <v-card>
                  <v-card-text>
                    {{ SEP }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-text-field
                  v-model="noHp"
                  outlined
                  dense
                  label="No. Hp Pasien"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-text-field
                  v-model="NamaPasien"
                  label="Nama Pasien"
                  disabled
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-text-field
                  v-model="NoRm"
                  disabled
                  dense
                  label="No. Rekam Medis"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <div v-if="data.form1.rencana_tindak_lanjut != ''">
                  <p>
                    <strong>Rencana Tindak Lanjut : </strong>
                    <span
                      v-if="
                        data.form1.rencana_tindak_lanjut.text ==
                        'Kontrol Ulang Ke Poliklinik,'
                      "
                      ><strong>Kontrol Ulang Ke </strong
                      >{{ data.form1.rencana_tindak_lanjut.value }}</span
                    >
                    <span
                      v-if="
                        data.form1.rencana_tindak_lanjut ==
                        'Kembali Ke Faskes TK 1'
                      "
                      >Kembali Ke Faskes TK 1</span
                    >
                    <span
                      v-if="
                        data.form1.rencana_tindak_lanjut ==
                        'Tidak Perlu Kontrol Ulang'
                      "
                      >Tidak Perlu Kontrol Ulang</span
                    >
                  </p>
                  <p>
                    <strong>Tanggal : </strong>
                    {{
                      data.form1?.rencana_tindak_lanjut_tanggal
                        | moment("DD MMMM YYYY")
                    }}
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                lg="12"
                md="12"
                v-if="listLogs.length > 0"
              >
                <p><b>List Log</b></p>
                <ul
                  class="list-group"
                  v-for="(li, indexLog) in listLogs"
                  :key="indexLog"
                >
                  <li class="list-group-item mt-1">
                    {{ li?.request?.message }}
                    {{ li }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="red" @click="showDialog = false">BATAL</v-btn>
          <v-btn text color="green" @click="simpansuratkontrol()">KIRIM</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/resume_medis_rawat_inap'
import Dialog from '@/components/Dialog'
// import { apiKedua } from '../../plugins/supports'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      SEP: '',
      noNik: '',
      noHp: '',
      NamaPasien: '',
      NoRm: '',
      pesan: '',
      disabled: false,
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      search: '', // This is where the search input will be bound
      user: JSON.parse(localStorage.getItem('user')),
      showDialog: false,
      loading: false,
      persistent: true,
      itemsKamar: [],
      items: [],
      listLogs: [],
      selectedItem: {},
      master: Master,
      max: '780px',
      validForm: true,
      rulesSep: [
        (v) =>
          !!v || 'No.SEP Tidak Boleh Kosong, Pastikan Inputan SEPnya Benar!'
      ],
      rulesJeniskunjungan: [
        (v) => !!v || 'Jenis Kunjungan Tidak Boleh Kosong!'
      ],
      rulesAlasanRujukan: [(v) => !!v || 'Alasan Rujukan Tidak Boleh Kosong!'],
      valid: true,

      listData: '',
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          tgl_masuk: '',
          jam_pulang: '',
          diagnosa_masuk: '',
          indikasi_rawat_inap: '',
          ringkasan_riwayat_penyakit: '',
          pemeriksaan_fisik: '',
          hasil_pemeriksaan_penunjang_yang_penting: '',
          diagnosa_utama: '',
          diagnosa_utama_icd10: '',
          diagnosa_sekunder1: '',
          diagnosa_sekunder1_icd10: '',
          diagnosa_sekunder2: '',
          diagnosa_sekunder2_icd10: '',
          diagnosa_sekunder3: '',
          diagnosa_sekunder3_icd10: '',
          diagnosa_sekunder4: '',
          diagnosa_sekunder4_icd10: '',
          diagnosa_sekunder5: '',
          diagnosa_sekunder5_icd10: '',
          tindakan_prosedur_operasi1: '',
          tindakan_prosedur_operasi1_icd9: '',
          tindakan_prosedur_operasi2: '',
          tindakan_prosedur_operasi2_icd9: '',
          tindakan_prosedur_operasi3: '',
          tindakan_prosedur_operasi3_icd9: '',
          terapi_selama_di_rs: '',
          alergi_obat: '',
          terapi_pulang: '',
          diet: '',
          prognosa: '',
          kondisi_pasien_saat_pulang: '',
          cara_keluar_rumah_sakit: '',
          rencana_tindak_lanjut: '',
          rencana_tindak_lanjut_tanggal: '',
          poliklinik_yang_dituju: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },

  created () {
    // this.selectedItem = {
    //   id: '957',
    //   text: '703-D'
    // }
    if (!this.$route.query.no_rm) {
      errorMsg('PILIH DAHULU PASIENNYA')
      this.$router.push({
        name: 'DataPasienRawatInap'
      })
    }
    if (this.$route.query.no_rm != null) {
      this.getAssemen(this.$route.query.folio_id)
      this.getDataPasien(this.$route.query.folio_id)
      this.getListResumeMedis()
      setTimeout(() => {
        this.getMedisAsesment()
        this.getSep()
        this.cariSep()
        this.listLog()
      }, 150)
    }
  },
  methods: {
    fetchItems (query) {
      // console.log(query)
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/cari-bed?q=' + query,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { results } = res
          // console.log(results)
          this.itemsKamar = results
        },
        'JSON'
      )
    },

    cekRole () {
      // eslint-disable-next-line no-unused-vars
      const ARRAYCASEMIX = ['1523', '599', '1017', '1767', '1342']
      if (ARRAYCASEMIX.includes(this.user.employee_id)) {
        return true
      } else {
        return false
      }
    },
    gantiruangan () {
      // eslint-disable-next-line no-unused-vars
      const data = {
        folio_id: this.$route.query.folio_id,
        sub_unit_id: this.selectedItem
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-pergantian-ruangan',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
      console.log(data)
    },
    cetakResumeMedis (VALUE) {
      window
        .open(
          baseUrlEmr +
            `laporan-medis/resume-medis-rawat-inap?params=${window.btoa(
              VALUE.id_resume_rawat_inap
            )}`,
          '_blank'
        )
        .focus()
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.noNik = results.NIK
            this.noHp = results.HP
            this.NamaPasien = results.nama_pasien
            this.NoRm = results.no_rekam_medis
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
            // this.selectedItem = {
            //   id: results.ruangan_id,
            //   text: results.ruangan
            // }
          } else {
          }
        },
        'JSON'
      )
    },
    postSave () {
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Resume Medis Rawat Inap'
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-resume-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.getListResumeMedis()
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    listLog () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'bpjs-v2/list-log?reg_id=' +
          this.$route.query.registration_id,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            this.listLogs = results
          } else {
            this.listLogs = []
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Resume Medis Rawat Inap'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-resume-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    },
    cariSep () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'bpjs-v2/list-sep?no_rm=' + this.$route.query.no_rm,
        (response) => {
          const { con, results } = response
          if (con) {
            this.items = results
          } else {
            console.log('DATA TIDAK DITEMUKAN')
          }
        },
        'JSON'
      )
      this.$forceUpdate()
    },
    getListResumeMedis () {
      const norm = this.$route.query.no_rm
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-resume-rawat-inap-list',
        { rm: norm },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            const listData = []
            for (const item of results) {
              // idcArray.push(item.text)
              listData.push({
                id_resume_rawat_inap: item.id_resume_rawat_inap,
                dokterUtama: item.dokterUtama,
                created_at: item.created_at
              })

              console.log(item)
            }
            this.listData = listData
          }
        }
      )
    },
    getMedisAsesment () {
      const folioid = this.$route.query.folio_id
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap-to-append',
        { folio_id: folioid },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            // const listData = []
            const data = results

            const pemeriksaan = JSON.parse(data.pemeriksaan)
            const asesmentDignosaMasuk = pemeriksaan.form4.p3
            const riwayatPenyakitSekarang =
              pemeriksaan.form1.p2 + '\n' + pemeriksaan.form1.p3
            console.log(riwayatPenyakitSekarang)
            // const

            var diagnosaMasuk = this.data.form1.diagnosa_masuk
            if (typeof diagnosaMasuk !== 'undefined') {
              var brackets = diagnosaMasuk.match(/\([^)]*\)/g)

              var appnedKeluhanUtamaMasuk = '(#: ' + asesmentDignosaMasuk + ')'
              // console.log(appnedKeluhanUtamaMasuk)

              this.item_ke = null
              if (brackets !== null) {
                brackets.forEach((item, index) => {
                  if (item.includes('(#: ')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.diagnosa_masuk =
                    this.data.form1.diagnosa_masuk.replace(
                      brackets[this.item_ke],
                      appnedKeluhanUtamaMasuk
                    )
                } else {
                  this.data.form1.diagnosa_masuk = this.appendTechnique(
                    this.data.form1.diagnosa_masuk,
                    appnedKeluhanUtamaMasuk
                  )
                }
              } else {
                this.data.form1.diagnosa_masuk = this.appendTechnique(
                  this.data.form1.diagnosa_masuk,
                  appnedKeluhanUtamaMasuk
                )
              }
            } else {
              var appendKeluhanUtamaMasukS =
                '(#: ' + asesmentDignosaMasuk + ')'
              this.data.form1.diagnosa_masuk = appendKeluhanUtamaMasukS
            }

            // riwayat penyakit skrg
            var riwayatPenyakit = this.data.form1.ringkasan_riwayat_penyakit
            if (typeof riwayatPenyakit !== 'undefined') {
              var bracketsriwayatPenyakit = riwayatPenyakit.match(/\([^)]*\)/g)

              var appendRiwatPenyakit = '(#: ' + riwayatPenyakitSekarang + ')'
              // console.log(appendRiwatPenyakit)

              this.item_ke = null
              if (bracketsriwayatPenyakit !== null) {
                bracketsriwayatPenyakit.forEach((item, index) => {
                  if (item.includes('(#: ')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.ringkasan_riwayat_penyakit =
                    this.data.form1.ringkasan_riwayat_penyakit.replace(
                      bracketsriwayatPenyakit[this.item_ke],
                      appendRiwatPenyakit
                    )
                } else {
                  this.data.form1.ringkasan_riwayat_penyakit =
                    this.appendTechnique(
                      this.data.form1.ringkasan_riwayat_penyakit,
                      appendRiwatPenyakit
                    )
                }
              } else {
                this.data.form1.ringkasan_riwayat_penyakit =
                  this.appendTechnique(
                    this.data.form1.ringkasan_riwayat_penyakit,
                    appendRiwatPenyakit
                  )
              }
            } else {
              var appendRiwatPenyakitA = '(#: ' + riwayatPenyakitSekarang + ')'
              this.data.form1.ringkasan_riwayat_penyakit = appendRiwatPenyakitA
            }
          }
        }
      )
    },

    cektgl () {
      var dtToday = new Date()

      var month = dtToday.getMonth() + 1
      var day = dtToday.getDate()
      var year = dtToday.getFullYear()
      if (month < 10) {
        month = '0' + month.toString()
      }
      if (day < 10) {
        day = '0' + day.toString()
      }
      var maxDate = year + '-' + month + '-' + day
      // eslint-disable-next-line no-undef
      $('#inputdate').attr('min', maxDate)
    },
    appendTechnique (satu, dua) {
      // var a = 'how you are'
      if (satu.indexOf(dua) > -1) {
        // If already contained
        return satu
      }
      if (satu !== '' && satu !== dua) {
        // If not empty and not the same input as current
        return satu + '\n' + dua
      } else {
        return dua
      }
    },
    getSep () {
      // const regid = this.$route.query.registration_id
      const regid = this.$route.query.registration_id
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-sep-rawat-inap',
        { reg_id: regid },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            // const listData = []
            // for (const item of results) {
            //   // idcArray.push(item.text)
            //   listData.push({
            //     id_resume_rawat_inap: item.id_resume_rawat_inap,
            //     dokterUtama: item.dokterUtama,
            //     created_at: item.created_at
            //   })
            //   console.log(item)
            // }
            // this.listData = listData
          }
        }
      )
    },
    simpansuratkontrol () {
      if (this.$refs.form.validate()) {
        // eslint-disable-next-line no-unused-vars
        const data = {
          sub_unit_id: this.$route.query.sub_unit_id,
          noSep: this.SEP.id,
          nik: this.noNik,
          pegawai: this.user.employee_name,
          registration_id: this.$route.query.registration_id,
          folio_id: this.$route.query.folio_id,
          NoRm: this.$route.query.no_rm,
          noHp: this.noHp,
          poliklinik_yang_dituju: this.data.form1.poliklinik_yang_dituju,
          tgl_kontrol: this.data.form1?.rencana_tindak_lanjut_tanggal
        }
        // eslint-disable-next-line no-undef
        $.post(
          baseUrl + 'rawat-inap/insert-v2',
          data,
          (res) => {
            // eslint-disable-next-line no-unused-vars
            const { con, msg, results } = res
            if (con) {
              successMsg(msg)
              this.listLog()
            } else {
              errorMsg(msg)
              this.listLog()
            }
          },
          'JSON'
        )
      }
    }
  },
  watch: {
    search (query) {
      this.fetchItems(query)
    },
    'data.form1.poliklinik_yang_dituju' (a, b) {
      console.log(a)

      if (
        this.data.form1.rencana_tindak_lanjut.text ===
        'Kontrol Ulang Ke Poliklinik,'
      ) {
        this.data.form1.rencana_tindak_lanjut.value = a.text
      }

      const subUnitId = a.id
      const tanggal = this.data.form1.rencana_tindak_lanjut_tanggal
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-jadwal-dokter',
        { sub_unit: subUnitId, tanggal: tanggal },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.disabled = false
          } else {
            errorMsg(msg)
            this.disabled = true
          }
        },
        'JSON'
      )
    },
    'data.form1.rencana_tindak_lanjut_tanggal' (a, b) {
      // console.log(a)

      const subUnitId = this.data.form1.poliklinik_yang_dituju.id
      const tanggal = this.data.form1.rencana_tindak_lanjut_tanggal
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-jadwal-dokter',
        { sub_unit: subUnitId, tanggal: tanggal },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.disabled = false
          } else {
            errorMsg(msg)
            this.disabled = true
          }
        },
        'JSON'
      )
    },
    'data.form1.diagnosa_utama_icd10' (a, b) {
      // console.log(a + '1111')
      // console.log(this.data.isian_dokter.diagnosa_utama)
      // eslint-disable-next-line no-unused-vars
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }
        // console.log(idcArray)
        // eslint-disable-next-line no-unused-vars
        var diagnosa = idcArray.join(',')
        // console.log(diagnosa)
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.form1.diagnosa_utama
        // console.log(data + ' anak')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          console.log(brackets + 'style')
          // console.log(brackets)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          // console.log(appendKeSubjektifDokter + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.form1.diagnosa_utama =
                this.data.form1.diagnosa_utama.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.form1.diagnosa_utama = this.appendTechnique(
                this.data.form1.diagnosa_utama,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.form1.diagnosa_utama = this.appendTechnique(
              this.data.form1.diagnosa_utama,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.diagnosa_utama = appendKeSubjektifDokters
        }
      }
    },
    'data.form1.diagnosa_sekunder1_icd10' (a, b) {
      // console.log(a + '1111')
      // console.log(this.data.isian_dokter.diagnosa_utama)
      // eslint-disable-next-line no-unused-vars
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }
        // console.log(idcArray)
        // eslint-disable-next-line no-unused-vars
        var diagnosa = idcArray.join(',')
        // console.log(diagnosa)
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.form1.diagnosa_sekunder1
        // console.log(data + ' anak')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          console.log(brackets + 'style')
          // console.log(brackets)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          // console.log(appendKeSubjektifDokter + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.form1.diagnosa_sekunder1 =
                this.data.form1.diagnosa_sekunder1.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.form1.diagnosa_sekunder1 = this.appendTechnique(
                this.data.form1.diagnosa_sekunder1,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.form1.diagnosa_sekunder1 = this.appendTechnique(
              this.data.form1.diagnosa_sekunder1,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.diagnosa_sekunder1 = appendKeSubjektifDokters
        }
      }
    },
    'data.form1.diagnosa_sekunder2_icd10' (a, b) {
      // console.log(a + '1111')
      // console.log(this.data.isian_dokter.diagnosa_utama)
      // eslint-disable-next-line no-unused-vars
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }
        // console.log(idcArray)
        // eslint-disable-next-line no-unused-vars
        var diagnosa = idcArray.join(',')
        // console.log(diagnosa)
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.form1.diagnosa_sekunder2
        // console.log(data + ' anak')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          console.log(brackets + 'style')
          // console.log(brackets)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          // console.log(appendKeSubjektifDokter + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.form1.diagnosa_sekunder2 =
                this.data.form1.diagnosa_sekunder2.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.form1.diagnosa_sekunder2 = this.appendTechnique(
                this.data.form1.diagnosa_sekunder2,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.form1.diagnosa_sekunder2 = this.appendTechnique(
              this.data.form1.diagnosa_sekunder2,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.diagnosa_sekunder2 = appendKeSubjektifDokters
        }
      }
    }
  }
}
</script>

<style scoped>
.float-group {
  position: fixed;
  bottom: 10%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
