export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Utama',
      disabled: true,

      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Kedua',
      disabled: true,

      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      disabled: true,
      label: 'No Reg',
      col: 6
    }
  },
  form1: {
    label01: {
      label: 'Tanggal Masuk :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    tgl_masuk: {
      label: ' ',
      widget: 'wdatenormal',
      col: 9,
      data: []
    },
    label02: {
      label: 'Jam Pulang :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    jam_pulang: {
      label: ' ',
      widget: 'wtime',
      col: 9,
      data: []
    },
    label1: {
      label: 'Diagnosa Masuk :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    diagnosa_masuk: {
      label: ' ',
      widget: 'wtextarea',
      col: 9,
      data: []
    },
    label2: {
      label: 'Indikasi Rawat Inap',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    indikasi_rawat_inap: {
      label: ' ',
      widget: 'wtextarea',
      col: 9,
      data: []
    },
    label3: {
      label: 'Ringkasan Riwayat Penyakit / Anamnesa',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    ringkasan_riwayat_penyakit: {
      label: ' ',
      widget: 'wtextarea',
      col: 9,
      data: []
    },
    label4: {
      label: 'Pemeriksaan Fisik',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    pemeriksaan_fisik: {
      label: ' ',
      widget: 'wtextarea',
      col: 9,
      data: []
    },
    label5: {
      label: 'Hasil Pemeriksaan Penunjang yang Penting',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    hasil_pemeriksaan_penunjang_yang_penting: {
      label: ' ',
      widget: 'wtextarea',
      col: 9,
      data: []
    },
    label6: {
      label: 'Diagnosa Utama',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_utama: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: []
    },
    label6icd: {
      label: 'ICD 10',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_utama_icd10: {
      label: ' ',
      widget: 'wautocomplate',
      col: 4,
      data: []
    },
    label7: {
      label: 'Diagnosa Sekunder',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder1: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: [],
      property: { attrs: { prefix: '1.' } }
    },
    label71icd: {
      label: 'ICD 10',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder1_icd10: {
      label: ' ',
      widget: 'wautocomplate',
      col: 4,
      data: []
    },
    label7_2: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder2: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: [],
      property: { attrs: { prefix: '2.' } }
    },
    label72icd: {
      label: 'ICD 10',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder2_icd10: {
      label: ' ',
      widget: 'wautocomplate',
      col: 4,
      data: []
    },
    label7_3: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder3: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: [],
      property: { attrs: { prefix: '3.' } }
    },
    label73icd: {
      label: 'ICD 10',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder3_icd10: {
      label: ' ',
      widget: 'wautocomplate',
      col: 4,
      data: []
    },
    label7_4: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder4: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: [],
      property: { attrs: { prefix: '4.' } }
    },
    label74icd: {
      label: 'ICD 10',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder4_icd10: {
      label: ' ',
      widget: 'wautocomplate',
      col: 4,
      data: []
    },
    label7_5: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder5: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: [],
      property: { attrs: { prefix: '5.' } }
    },
    label75icd: {
      label: 'ICD 10',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diagnosa_sekunder5_icd10: {
      label: ' ',
      widget: 'wautocomplate',
      col: 4,
      data: []
    },
    label8: {
      label: 'Tindakan / Prosedur Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    tindakan_prosedur_operasi1: {
      label: ' ',
      widget: 'wtextarea',
      col: 5,
      data: [],
      property: { attrs: { prefix: '1.' } }
    },
    label81icd: {
      label: 'ICD 9',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    tindakan_prosedur_operasi1_icd9: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: []
    },
    label8_2: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    tindakan_prosedur_operasi2: {
      label: ' ',
      widget: 'wtextarea',
      col: 5,
      data: [],
      property: { attrs: { prefix: '2.' } }
    },
    label82icd: {
      label: 'ICD 9',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    tindakan_prosedur_operasi2_icd9: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: []
    },
    label8_3: {
      label: ' ',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    tindakan_prosedur_operasi3: {
      label: ' ',
      widget: 'wtextarea',
      col: 5,
      data: [],
      property: { attrs: { prefix: '3.' } }
    },
    label83icd: {
      label: 'ICD 9',
      widget: 'wlabelkiri',
      data: [],
      col: 1
    },
    tindakan_prosedur_operasi3_icd9: {
      label: ' ',
      widget: 'wtextarea',
      col: 4,
      data: []
    },
    label9: {
      label: 'Terapi Selama di Rumah Sakit',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    terapi_selama_di_rs: {
      label: ' ',
      widget: 'wtextarea',
      col: 10,
      data: []
    },
    label10: {
      label: 'Alergi Obat',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    alergi_obat: {
      label: ' ',
      widget: 'wtextarea',
      col: 10,
      data: []
    },
    label11: {
      label: 'Terapi Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    terapi_pulang: {
      label: ' ',
      widget: 'wtextarea',
      col: 10,
      data: []
    },
    label12: {
      label: 'Diet',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diet: {
      label: ' ',
      widget: 'wtextarea',
      col: 10,
      data: []
    },
    label13: {
      label: 'Prognosa',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    prognosa: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Dubia Ad Bonam', value: 'Dubia Ad Bonam' },
        { text: 'Dubia Ad Malam', value: 'Dubia Ad Malam' }
      ]
    },
    label14: {
      label: 'Kondisi Pasien Saat Pulang',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kondisi_pasien_saat_pulang: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Sembuh', value: 'Sembuh' },
        { text: 'Membaik', value: 'Membaik' },
        { text: 'Belum Sembuh', value: 'Belum Sembuh' },
        { text: 'Meninggal > 48 Jam', value: 'Meninggal > 48 Jam' },
        { text: 'Meninggal < 48 Jam', value: 'Meninggal < 48 Jam' }
      ]
    },
    label15: {
      label: 'Cara Keluar Rumah Sakit',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    cara_keluar_rumah_sakit: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Izin Dokter', value: 'Izin Dokter' },
        { text: 'PAPS', value: 'PAPS' },
        { text: 'Meninggal', value: 'Meninggal' },
        { text: 'Dirujuk ke RS Lain', value: null, field: 'auto' }
      ]
    },
    label16: {
      label: 'Rencana Tindak Lanjut',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    rencana_tindak_lanjut: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        {
          text: 'Tidak Perlu Kontrol Ulang',
          value: 'Tidak Perlu Kontrol Ulang'
        },
        { text: 'Kembali Ke Faskes TK 1', value: 'Kembali Ke Faskes TK 1' },
        { text: 'Kontrol Ulang Ke Poliklinik,', value: null, field: 'auto' }
      ]
    },
    poliklinik_yang_dituju: {
      label: 'Poliklinik Yang Dituju',
      widget: 'wautocomplatesingle',
      col: 4,
      data: ''
    },
    // label16a: {
    //   label: ' ',
    //   widget: 'wlabelkiri',
    //   data: [],
    //   col: 2
    // },
    label16b: {
      label: 'Jika Kontrol Ulang Ke Poliklinik, Tanggal : ',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    rencana_tindak_lanjut_tanggal: {
      label: ' ',
      widget: 'wdatenormal',
      col: 4,
      data: []
    }
    // label18: {
    //   label: 'Status Daftarkan Pasien',
    //   widget: 'wlabelkiri',
    //   data: [],
    //   col: 4
    // },
    // daftarkanpasien: {
    //   label: ' ',
    //   widget: 'wradio',
    //   col: 8,
    //   data: [
    //     { text: 'Ya Daftarkan Pasien', value: 'Ya Daftarkan Pasien' },
    //     { text: 'Tidak Daftarkan Pasien', value: 'Tidak Daftarkan Pasien' }
    //   ]
    // }
  }
}
